<template>
  <div>
    <numerations-manager ref="NumerationsManager"/>
    <show-numerations ref="ShowNumerations"/>
  </div>
</template>

<script>
  export default {
    components: {
      NumerationsManager: () => import('./components/numerations/NumerationsManager.vue'),
      ShowNumerations: () => import('./components/numerations/ShowNumerations'),
    },
  }
</script>


